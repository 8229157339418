/* eslint-disable */
import { createStore } from 'vuex'
import axios from 'axios'
import { darkModeKey, styleKey } from '@/config.js'
import * as styles from '@/styles.js'
import { Auth } from '@aws-amplify/auth'
import dayjs from 'dayjs'

export default createStore({
  state: {
    /* Styles */
    lightBorderStyle: '',
    lightBgStyle: '',
    asideStyle: '',
    asideBrandStyle: '',
    asideMenuCloseLgStyle: '',
    asideMenuLabelStyle: '',
    asideMenuItemStyle: '',
    asideMenuItemActiveStyle: '',
    asideMenuItemInactiveStyle: '',
    asideSubmenuListStyle: '',
    navBarItemLabelStyle: '',
    navBarItemLabelHoverStyle: '',
    navBarItemLabelActiveColorStyle: '',
    navBarMenuListUpperLabelStyle: '',
    tableTrStyle: '',
    tableTrOddStyle: '',
    overlayStyle: '',

    /* User */
    userAws: null,
    userId: null,
    userName: null,
    userGivenName: null,
    userLastName: null,
    userEmail: null,
    userPhone: null,
    userAvatar: null,
    userTenant: null,
    userType: null,
    userIp: null,

    filterHistory: 0,
    filterMeasureYear: dayjs().format('YYYY'),
    filterMeasureMonth: dayjs().add(-1, 'M').format('M'),
    filterMetricCategory: 'BSQ',
    filterMetricType: 'TA',
    filterHotelType: 'All',
    filterAssetClass: 'All',
    filterStarLevel: 'All',
    filterMarketSegment: 'All',

    /* fullScreen - fullscreen form layout (e.g. login page) */
    isFullScreen: true,

    /* Aside */
    isAsideMobileExpanded: false,
    isAsideLgActive: false,

    /* Dark mode */
    darkMode: false,

    /* Field focus with ctrl+k (to register only once) */
    isFieldFocusRegistered: false,

    /* Sample data (commonly used) */
    clients: [],
    history: []
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* Styles */
    styles (state, payload) {
      for (const key in payload) {
        if (['body', 'html'].includes(key)) {
          continue
        }

        state[`${key}Style`] = payload[key]
      }
    },

    /* User */
    user (state, payload) {
      if (payload.aws) {
        state.userAws = payload.aws
      }
      if (payload.id) {
        state.userId = payload.id
      }
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.given_name) {
        state.userGivenName = payload.given_name
      }
      if (payload.last_name) {
        state.userLastName = payload.last_name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.phone) {
        state.userPhone = payload.phone
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
      if (payload.tenant_id) {
        state.userTenant = payload.tenant_id
      }
      if (payload.user_type) {
        state.userType = payload.user_type
      }
      if (payload.user_ip) {
        state.userIp = payload.user_ip
      }
    },

    /* filter */
    filter (state, payload) {
      if (payload.filter_history) {
        state.filterHistory = payload.filter_history
      }
      if (payload.measure_year) {
        state.filterMeasureYear = payload.measure_year
      }
      if (payload.measure_mon) {
        state.filterMeasureMonth = payload.measure_mon
      }
      if (payload.metric_cat) {
        state.filterMetricCategory = payload.metric_cat
      }
      if (payload.metric_type) {
        state.filterMetricType = payload.metric_type
      }
      if (payload.hotel_type) {
        state.filterHotelType = payload.hotel_type
      }
      if (payload.asset_class) {
        state.filterAssetClass = payload.asset_class
      }
      if (payload.star_level) {
        state.filterStarLevel = payload.star_level
      }
      if (payload.market_segment) {
        state.filterMarketSegment = payload.market_segment
      }
    }
  },
  actions: {
    setUser ({ commit }, payload) {
      if (payload['custom:user_profile_id']) {
        commit('user', {
          aws: payload.sub,
          id: payload['custom:user_profile_id'],
          name: payload.given_name + ' ' + payload.family_name,
          given_name: payload.given_name,
          last_name: payload.family_name,
          email: payload.email,
          phone: payload.phone_number,
          tenant_id: payload['custom:default_tenant_id'],
          user_type: payload['custom:user_type'],
          avatar: 'https://greenbalance-asset-dev.s3.ap-southeast-1.amazonaws.com/app/logo.png'
        })
      } else {
        return new Promise(async (resolve, reject) => {
          try {
            const user = await Auth.currentAuthenticatedUser()
            setTimeout(async () => resolve(await user), 2000)
            commit('user', {
              aws: user.attributes.sub,
              id: user.attributes['custom:user_profile_id'],
              name: user.attributes.given_name + ' ' + user.attributes.family_name,
              given_name: user.attributes.given_name,
              last_name: user.attributes.family_name,
              email: user.attributes.email,
              phone: user.attributes.phone_number,
              tenant_id: user.attributes['custom:default_tenant_id'],
              user_type: user.attributes['custom:user_type'],
              avatar: 'https://greenbalance-asset-dev.s3.ap-southeast-1.amazonaws.com/app/logo.png'
            })
          } catch (e) {
            console.log(e)
          }
        })
      }
    },

    setFilter ({ commit }, payload) {
      commit('filter', {
        filter_history: 1,
        measure_year: payload.measure_year,
        measure_mon: payload.measure_mon,
        metric_cat: payload.metric_cat,
        metric_type: payload.metric_type,
        hotel_type: payload.hotel_type ?? 'All',
        asset_class: payload.asset_class ?? 'All',
        star_level: payload.star_level ?? 'All',
        market_segment: payload.market_segment ?? 'All',
      })
    },

    setIP ({ commit }, payload) {
      commit('user', { user_ip: payload })
    },

    setStyle ({ commit, dispatch }, payload) {
      const style = styles[payload] ?? styles.basic

      document.body.className = style.body
      document.documentElement.className = style.html

      if (localStorage[styleKey] !== payload) {
        localStorage.setItem(styleKey, payload)
      }

      commit('styles', style)
    },

    resetUserState ({ state }) {
      state.userAws = null
      state.userId = null
      state.userName = null
      state.userGivenName = null
      state.userLastName = null
      state.userEmail = null
      state.userPhone = null
      state.userAvatar = null
      state.userTenant = null
      state.userType = null
    },

    asideMobileToggle ({ commit, state }, payload = null) {
      const isShow = payload !== null ? payload : !state.isAsideMobileExpanded

      document.getElementById('app').classList[isShow ? 'add' : 'remove']('ml-60', 'lg:ml-0')

      document.documentElement.classList[isShow ? 'add' : 'remove']('m-clipped')

      commit('basic', {
        key: 'isAsideMobileExpanded',
        value: isShow
      })
    },

    asideLgToggle ({ commit, state }, payload = null) {
      commit('basic', { key: 'isAsideLgActive', value: payload !== null ? payload : !state.isAsideLgActive })
    },

    fullScreenToggle ({ commit, state }, value) {
      commit('basic', { key: 'isFullScreen', value })

      document.documentElement.classList[value ? 'add' : 'remove']('full-screen')
    },

    darkMode ({ commit, state }, payload = null) {
      const value = payload !== null ? payload : !state.darkMode

      document.documentElement.classList[value ? 'add' : 'remove']('dark')

      localStorage.setItem(darkModeKey, value ? '1' : '0')

      commit('basic', {
        key: 'darkMode',
        value
      })
    },

    fetch ({ commit }, payload) {
      axios
        .get(`data-sources/${payload}.json`)
        .then((r) => {
          if (r.data && r.data.data) {
            commit('basic', {
              key: payload,
              value: r.data.data
            })
          }
        })
        .catch(error => {
          alert(error.message)
        })
    }
  },
  modules: {
  }
})
