import $axios from '@/utils/axios'
import { Auth } from 'aws-amplify'
import axios from 'axios'

$axios.interceptors.request.use(async (config) => {
  // config.headers['Authorization'] = `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
  const session = await Auth.currentSession()
  const idToken = session.getIdToken().getJwtToken()
  config.headers.common.Authorization = `Bearer ${idToken}`
  return config
},
error => {
  return Promise.reject(error)
}
)
$axios.defaults.timeout = 180000

const s3Bucket = 'greenbalance-asset-prod'

const apis = {
  getAuthenticateUser () {
    return new Promise(function (resolve, reject) {
      Auth.currentAuthenticatedUser().then(
        (response) => {
          const result = response
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getTreeNodes () {
    return new Promise(function (resolve, reject) {
      $axios.get('/library', { params: { tenant: 'HTL', cat: 'metric_type' } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  async getLoginUserDetail (i) {
    return new Promise(function (resolve, reject) {
      $axios.get('/loginuser/detail', { params: { id: i } }).then(
        (response) => {
          const result = Object.keys(response.data).map(function (key) {
            return [Number(key), response.data[key]]
          })
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getLibrary (t, c) {
    return new Promise(function (resolve, reject) {
      $axios.get('/library', { params: { tenant: t, cat: c } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getTenantList (t) {
    return new Promise(function (resolve, reject) {
      $axios.get('/tenant', { params: { tenant: t } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getTenantDetail (t) {
    return new Promise(function (resolve, reject) {
      $axios.get('/tenant/detail', { params: { tenant: t } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  postTenantDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.post('/tenant/detail', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  putTenantDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.put('/tenant/detail', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getTenantUser (t) {
    return new Promise(function (resolve, reject) {
      $axios.get('/tenant/user', { params: { tenant: t } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getTenantDataPoint (t) {
    return new Promise(function (resolve, reject) {
      $axios.get('/tenant/datapoint', { params: { tenant: t } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getChartTenantCarbon (t, d) {
    return new Promise(function (resolve, reject) {
      $axios.get('/chart-carbon', { params: { type: t, data: d } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getChartTenantWater (t, d) {
    return new Promise(function (resolve, reject) {
      $axios.get('/chart-water', { params: { type: t, data: d } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getChartTenantWaste (t, d) {
    return new Promise(function (resolve, reject) {
      $axios.get('/chart-waste', { params: { type: t, data: d } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getChartTenantEnergy (t, d) {
    return new Promise(function (resolve, reject) {
      $axios.get('/chart-energy', { params: { type: t, data: d } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getDataPointDetail (t, i) {
    return new Promise(function (resolve, reject) {
      $axios.get('/datapoint/detail', { params: { tenant: t, datapoint: i } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  putDataPointDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.put('/datapoint/detail', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  postDataPointDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.post('/datapoint', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  uploadToS3 (file, folder) {
    return new Promise(function (resolve, reject) {
      const body = new FormData()
      body.append('file', file)
      const fileName = file.name

      $axios.post('/upload', file, {
        headers: {
          'Content-Type': 'image/' + fileName.split('.').pop().toLowerCase(),
          ACL: 'Public-Read'
        }
      }, { params: { bucket: s3Bucket, folder: folder } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  async addTenantImages (data) {
    return new Promise(function (resolve, reject) {
      $axios.post('/tenant/images', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getChartGreensight (t, d) {
    return new Promise(function (resolve, reject) {
      $axios.get('/greensight', { params: { type: t, data: d } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getChartTrend (t, d) {
    return new Promise(function (resolve, reject) {
      $axios.get('/trend', { params: { type: t, data: d } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getSignUpData (t, f) {
    return new Promise(function (resolve, reject) {
      axios.get('https://d8ndsrpct0.execute-api.ap-southeast-1.amazonaws.com/prod/signup', { params: { type: t, filter: f } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  postSignUpData (data) {
    return new Promise(function (resolve, reject) {
      axios.post('https://d8ndsrpct0.execute-api.ap-southeast-1.amazonaws.com/prod/signup', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  putSignUpData (data) {
    return new Promise(function (resolve, reject) {
      axios.put('https://d8ndsrpct0.execute-api.ap-southeast-1.amazonaws.com/prod/signup', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getFaqs (i, t) {
    return new Promise(function (resolve, reject) {
      $axios.get('/faq', { params: { id: i, type: t } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  putFaqDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.put('/faq', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  postFaqDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.post('/faq', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  deleteFaqDetail (d) {
    return new Promise(function (resolve, reject) {
      $axios.delete('/faq', { data: { id: d.id, user_id: d.user_id } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getFeedbacks (i, u) {
    return new Promise(function (resolve, reject) {
      $axios.get('/feedback', { params: { id: i, user_id: u } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  putFeedbackDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.put('/feedback', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  postFeedbackDetail (data) {
    return new Promise(function (resolve, reject) {
      $axios.post('/feedback', data).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  deleteFeedbackDetail (d) {
    return new Promise(function (resolve, reject) {
      $axios.delete('/feedback', { data: { id: d.id, user_id: d.user_id } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  deleteDataPoint (d) {
    return new Promise(function (resolve, reject) {
      $axios.delete('/datapoint', { data: { id: d.id, user_id: d.user_id } }).then(
        (response) => {
          const result = response.data
          resolve(result)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}

const install = (app) => {
  app.config.globalProperties.$a = () => {
    return apis
  }
}

export { install as default, apis as apisrv }
