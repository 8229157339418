<template>
  <Modal
    class="flex flex-row items-center justify-center h-screen"
    size="modal-sm"
    backdrop="static"
    :show="loadingOverlay"
    @hidden="loadingOverlay = false"
  >
    <ModalBody class="p-10 text-center">
      <div class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center">
        <img
          alt="Fetching the data..."
          src="https://greenbalance-asset-dev.s3.ap-southeast-1.amazonaws.com/app/logo-360.gif"
          class="max-h-40"
        >
      </div>
    </ModalBody>
  </Modal>
  <title-bar :title-stack="titleStack" />
  <main-section class="intro-y">
    <div class="grid grid-cols-1 gap-4 gap-y-5 mb-6">
      <card-component class="relative flex flex-col min-w-0 break-words w-full mt-16">
        <div class="flex flex-wrap justify-center">
          <div class="w-full flex justify-center">
            <div class="relative w-48 h-36">
              <img
                :alt="tenantDetail.tenant_name"
                :src="tenantDetail.photo_url ? tenantDetail.photo_url : 'https://greenbalance-asset-dev.s3.ap-southeast-1.amazonaws.com/tenant/1651901505318-hotel-logo_10250-628.jpg'"
                class="shadow-xl rounded-full h-auto align-middle border-none absolute -mt-20"
              >
            </div>
          </div>
          <div class="w-full px-4 text-center">
            <div class="mb-6">
              <h2 class="intro-y text-2xl font-medium text-center mr-auto">
                {{ tenantDetail.tenant_name }}
              </h2>
              <div class="text-slate-500 mt-3 mb-2">
                <font-awesome-icon
                  v-for="index in starCount"
                  :key="index"
                  icon="star"
                  size="lg"
                  color="#fdd835"
                />
                <font-awesome-icon
                  v-if="starHalf"
                  icon="star-half"
                  size="lg"
                  color="#fdd835"
                />
              </div>
              <h3 class="intro-y text-sm font-medium text-center mr-auto text-gray-500">
                <font-awesome-icon
                  icon="location-dot"
                  size="lg"
                />
                {{ tenantDetail.street_address }} {{ tenantDetail.city }} {{ tenantDetail.postal_zip }}
              </h3>
            </div>
            <div
              class="grid grid-cols-1 text-center"
              align="center"
            >
              <div class="text-center">
                <h3 class="text-sm leading-tight text-gray-500 dark:text-gray-400">
                  Your hotel is
                </h3>
                <h1
                  class="text-5xl leading-tight font-semibold"
                  :style="{'color':overAllPercentColor}"
                >
                  {{ overAllPercent }}%
                </h1>
                <h3
                  class="text-lg leading-tight font-bold mb-2 uppercase"
                  :style="{'color':overAllPercentColor}"
                >
                  {{ overAllPercentSign }} sustainable
                </h3>
                <h3 class="text-sm leading-tight text-gray-500 dark:text-gray-400">
                  than the industry average
                </h3>
              </div>
            </div>
          </div>
        </div>
      </card-component>
      <card-component>
        <div class="grid grid-cols-12 gap-4 gap-y-5">
          <div class="col-span-12 sm:col-span-3">
            <field label="Reporting Period">
              <control
                v-model="filter.measure_year"
                :options="libYears"
                @change="getGreensightGraphs()"
              />
            </field>
          </div>
          <div class="col-span-12 sm:col-span-3">
            <field label="Reporting Month">
              <control
                v-model="filter.measure_mon"
                :options="libMonths"
                @change="getGreensightGraphs()"
              />
            </field>
          </div>
          <div class="col-span-12 sm:col-span-3">
            <field label="Metric Type">
              <control
                v-model="filter.metric_cat"
                :options="libMetricType"
                @change="changeSubcategory()"
              />
            </field>
          </div>
          <div class="col-span-12 sm:col-span-3">
            <field label="Subcategory">
              <control
                v-model="filter.metric_type"
                :options="libSubMetric"
                @change="getGreensightGraphs()"
              />
            </field>
          </div>
        </div>
      </card-component>
    </div>
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
      <greensight-widget
        trend="0%"
        trend-type="alert"
        :number="tenantEnergy"
        suffix=" kWh"
        label="Energy"
        :grade="energyScore"
        :grade-text="energyScoreText"
        :grade-color="energyScoreColor"
        :percent="energyPercent"
        :percent-sign="energyPercentSign"
        :percent-color="energyPercentColor"
      />
      <greensight-widget
        trend="0%"
        trend-type="alert"
        :number="tenantCarbon"
        suffix=" KgCO2e"
        label="Carbon Footprint"
        :grade="carbonScore"
        :grade-text="carbonScoreText"
        :grade-color="carbonScoreColor"
        :percent="carbonPercent"
        :percent-sign="carbonPercentSign"
        :percent-color="carbonPercentColor"
      />
      <greensight-widget
        trend="0%"
        trend-type="alert"
        :number="tenantWater"
        suffix=" Litres"
        label="Water"
        :grade="waterScore"
        :grade-text="waterScoreText"
        :grade-color="waterScoreColor"
        :percent="waterPercent"
        :percent-sign="waterPercentSign"
        :percent-color="waterPercentColor"
      />
      <greensight-widget
        trend="0%"
        trend-type="alert"
        :number="tenantWaste"
        suffix=" Kg"
        label="Waste"
        :grade="wasteScore"
        :grade-text="wasteScoreText"
        :grade-color="wasteScoreColor"
        :percent="wastePercent"
        :percent-sign="wastePercentSign"
        :percent-color="wastePercentColor"
      />
    </div>
    <div class="grid grid-cols-1 lg:grid-cols-1">
      <card-component
        title="AVERAGE OVERVIEW"
      >
        <div class="grid grid-cols-1 lg:grid-cols-4">
          <div>
            <Chart
              type="bar"
              :width="tenantIndustryAvgWidth"
              :height="tenantIndustryAvgHeight"
              :data="tenantIndustryAvgEnergyData"
              :options="tenantIndustryAvgOptions"
            />
          </div>
          <div>
            <Chart
              type="bar"
              :width="tenantIndustryAvgWidth"
              :height="tenantIndustryAvgHeight"
              :data="tenantIndustryAvgCarbonData"
              :options="tenantIndustryAvgOptions"
            />
          </div>
          <div>
            <Chart
              type="bar"
              :width="tenantIndustryAvgWidth"
              :height="tenantIndustryAvgHeight"
              :data="tenantIndustryAvgWaterData"
              :options="tenantIndustryAvgOptions"
            />
          </div>
          <div>
            <Chart
              type="bar"
              :width="tenantIndustryAvgWidth"
              :height="tenantIndustryAvgHeight"
              :data="tenantIndustryAvgWasteData"
              :options="tenantIndustryAvgOptions"
            />
          </div>
        </div>
      </card-component>
    </div>
  </main-section>
</template>

<script>
import { getCurrentInstance } from 'vue'
import { apisrv as $a } from '@/utils/api'
import { helper as $h } from '@/utils/helper'
import {
  mdiLightningBolt, mdiWater, mdiRecycleVariant, mdiMoleculeCo2
} from '@mdi/js'
import TitleBar from '@/components/TitleBar.vue'
import MainSection from '@/components/MainSection.vue'
import GreensightWidget from '@/components/GreensightWidget.vue'
import CardComponent from '@/components/CardComponent.vue'
import Field from '@/components/Field.vue'
import Control from '@/components/Control.vue'
import { useStore } from 'vuex'
import { Auth } from 'aws-amplify'

export default {
  components: {
    TitleBar,
    MainSection,
    GreensightWidget,
    CardComponent,
    Field,
    Control
  },
  setup () {
    const store = useStore()
    const appCon = getCurrentInstance()
    return {
      store,
      mdiLightningBolt,
      mdiWater,
      mdiRecycleVariant,
      mdiMoleculeCo2,
      appCon,
      Auth
    }
  },
  data: () => {
    return {
      loadingOverlay: false,
      titleStack: ['Dashboard', 'Greensights'],
      filter: [],
      library: [],
      libYears: [],
      libMonths: [],
      libMetricType: [],
      libSubMetric: [],
      tenantDetail: [],
      starCount: 0,
      starHalf: false,
      overviewData: [],
      tenantEnergy: null,
      industryAvgEnergy: 0,
      industryAvgWater: 0,
      industryAvgWaste: 0,
      industryAvgCarbon: 0,
      energyPercent: '',
      energyPercentSign: '',
      energyPercentColor: '#000',
      energyScore: '',
      energyScoreText: '',
      energyScoreColor: '',
      tenantWater: null,
      waterPercent: '',
      waterPercentSign: '',
      waterPercentColor: '#000',
      waterScore: '',
      waterScoreText: '',
      waterScoreColor: '',
      tenantWaste: null,
      wastePercent: '',
      wastePercentSign: '',
      wastePercentColor: '#000',
      wasteScore: '',
      wasteScoreText: '',
      wasteScoreColor: '',
      tenantCarbon: null,
      carbonPercent: '',
      carbonPercentSign: '',
      carbonPercentColor: '#000',
      carbonScore: '',
      carbonScoreText: '',
      carbonScoreColor: '',
      overAllScore: '',
      overAllScoreText: '',
      overAllScoreColor: '',
      overAllPercent: '',
      overAllPercentSign: '',
      overAllPercentColor: '#000',
      tenantIndustryAvgWidth: 0,
      tenantIndustryAvgHeight: 280,
      tenantIndustryAvgEnergyData: {
        labels: [],
        datasets: [
          {
            label: 'Your Hotel',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#49654e', '#49654e', '#49654e', '#49654e']
          },
          {
            label: 'Industry Avg',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#8ba889', '#8ba889', '#8ba889', '#8ba889']
          }
        ]
      },
      tenantIndustryAvgWaterData: {
        labels: [],
        datasets: [
          {
            label: 'Your Hotel',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#49654e', '#49654e', '#49654e', '#49654e']
          },
          {
            label: 'Industry Avg',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#8ba889', '#8ba889', '#8ba889', '#8ba889']
          }
        ]
      },
      tenantIndustryAvgWasteData: {
        labels: [],
        datasets: [
          {
            label: 'Your Hotel',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#49654e', '#49654e', '#49654e', '#49654e']
          },
          {
            label: 'Industry Avg',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#8ba889', '#8ba889', '#8ba889', '#8ba889']
          }
        ]
      },
      tenantIndustryAvgCarbonData: {
        labels: [],
        datasets: [
          {
            label: 'Your Hotel',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#49654e', '#49654e', '#49654e', '#49654e']
          },
          {
            label: 'Industry Avg',
            borderColor: 'transparent',
            data: [],
            backgroundColor: ['#8ba889', '#8ba889', '#8ba889', '#8ba889']
          }
        ]
      },
      tenantIndustryAvgOptions: {
        parsing: false,
        normalized: true,
        animation: false,
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          labels: {
            color: '#424242',
            fontStyle: 'bold',
            pointStyle: 'circle'
          },
          align: 'center',
          position: 'bottom'
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2]
              },
              ticks: {
                fontSize: '15',
                fontStyle: 'bold'
              }
            }
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    tenandId: function () {
      return this.store.state.userTenant
    }
  },
  async created () {
    if (this.store.state.userId === null) {
      this.store.dispatch('setUser', Auth.user.attributes)
    }
    try {
      await this.getLibrary()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getLibrary () {
      this.loadingOverlay = true
      try {
        this.library = await $a.getLibrary('HTL', 'All')
        this.loadingOverlay = false
      } catch (err) {
        console.log(err)
      }
      this.libYears = $h.listYear()
      this.libMonths = $h.listMonths()
      if ((this.library?.length || 0) > 0) {
        this.libMetricType = this.library.filter(r => r.lib_cat === 'metric_type')
        this.filter.metric_cat = this.store.state.filterMetricCategory
        this.getTenantDetail(this.tenandId)
      }
    },
    changeSubcategory () {
      this.libSubMetric = []
      if (this.filter.metric_cat === 'BSQ') {
        this.libSubMetric = this.library.filter(r => r.lib_cat === 'by_square_meter')
        this.filter.metric_type = 'TA'
      } else {
        this.libSubMetric = this.library.filter(r => r.lib_cat === 'by_rooms')
        this.filter.metric_type = 'TR'
      }
      this.getGreensightGraphs()
    },
    async getTenantDetail (i) {
      this.loadingOverlay = true
      const result = await $a.getTenantDetail(i)
      if (result) {
        this.tenantDetail = result[0]
        this.filter.measure_year = (this.store.state.filterHistory === 0 ? result[0].last_data_year : this.store.state.filterMeasureYear)
        this.filter.measure_mon = (this.store.state.filterHistory === 0 ? result[0].last_data_month : this.store.state.filterMeasureMonth)
        this.starCount = parseInt(result[0].star_level, 10)
        this.starHalf = (parseFloat(result[0].star_level) - parseInt(result[0].star_level, 10) > 0)
        this.changeSubcategory()
      } else {
        this.getTenantDetail(i)
      }
      this.loadingOverlay = false
    },
    getGreensightGraphs () {
      try {
        this.store.dispatch('setFilter', this.filter)
        this.getChartTenantIndustryAverageOverview()
      } catch (err) {
        console.log(err)
      }
    },
    async getChartTenantIndustryAverageOverview () {
      const p = {
        tenant: this.tenandId,
        measure_year: this.filter.measure_year.toString(),
        measure_mon: this.filter.measure_mon.toString(),
        metric_type: this.filter.metric_type.toString()
      }
      try {
        this.overviewData = await $a.getChartGreensight('tenantIndustryAverageOverview', p)
      } catch (err) {
        console.log(err)
      }
      this.createTenantIndustryAverageOverviewGraph()
    },
    createTenantIndustryAverageOverviewGraph () {
      this.tenantIndustryAvgEnergyData.labels = []
      this.tenantIndustryAvgWaterData.labels = []
      this.tenantIndustryAvgWasteData.labels = []
      this.tenantIndustryAvgCarbonData.labels = []
      this.tenantIndustryAvgEnergyData.datasets[0].data = []
      this.tenantIndustryAvgEnergyData.datasets[1].data = []
      this.tenantIndustryAvgWaterData.datasets[0].data = []
      this.tenantIndustryAvgWaterData.datasets[1].data = []
      this.tenantIndustryAvgWasteData.datasets[0].data = []
      this.tenantIndustryAvgWasteData.datasets[1].data = []
      this.tenantIndustryAvgCarbonData.datasets[0].data = []
      this.tenantIndustryAvgCarbonData.datasets[1].data = []
      this.tenantEnergy = null
      this.tenantCarbon = null
      this.tenantWater = null
      this.tenantWaste = null
      for (let i = 0; i < (this.overviewData?.length || 0); i++) {
        if (this.overviewData[i].alias === 'YOUR HOTEL') {
          switch (this.overviewData[i].data_type) {
            case 'Energy Consumption':
              this.tenantEnergy = this.overviewData[i].chart_data
              this.tenantIndustryAvgEnergyData.labels.push(this.overviewData[i].data_type + ' ' + this.overviewData[i].suffix)
              this.tenantIndustryAvgEnergyData.datasets[0].data.push(this.overviewData[i].chart_data)
              // let se = $h.calculateLetterScore("energy", this.filter.metric_type.toString(), this.tenantEnergy);
              // this.energyScore = se.score;
              // this.energyScoreText = se.text;
              // this.energyScoreColor= se.color;
              break
            case 'Carbon Footprint':
              this.tenantCarbon = this.overviewData[i].chart_data
              this.tenantIndustryAvgCarbonData.labels.push(this.overviewData[i].data_type + ' ' + this.overviewData[i].suffix)
              this.tenantIndustryAvgCarbonData.datasets[0].data.push(this.overviewData[i].chart_data)
              // eslint-disable-next-line
              const sc = $h.calculateLetterScore('carbon', this.filter.metric_type.toString(), this.tenantCarbon)
              this.carbonScore = sc.score
              this.carbonScoreText = sc.text
              this.carbonScoreColor = sc.color
              break
            case 'Water Consumption':
              this.tenantWater = this.overviewData[i].chart_data
              this.tenantIndustryAvgWaterData.labels.push(this.overviewData[i].data_type + ' ' + this.overviewData[i].suffix)
              this.tenantIndustryAvgWaterData.datasets[0].data.push(this.overviewData[i].chart_data)
              // eslint-disable-next-line
              const swt = $h.calculateLetterScore('water', this.filter.metric_type.toString(), this.tenantWater)
              this.waterScore = swt.score
              this.waterScoreText = swt.text
              this.waterScoreColor = swt.color
              break
            case 'Waste Generation':
              this.tenantWaste = this.overviewData[i].chart_data
              this.tenantIndustryAvgWasteData.labels.push(this.overviewData[i].data_type + ' ' + this.overviewData[i].suffix)
              this.tenantIndustryAvgWasteData.datasets[0].data.push(this.overviewData[i].chart_data)
              // eslint-disable-next-line
              const sws = $h.calculateLetterScore('waste', this.filter.metric_type.toString(), this.tenantWaste)
              this.wasteScore = sws.score
              this.wasteScoreText = sws.text
              this.wasteScoreColor = sws.color
              break
          }
        } else {
          switch (this.overviewData[i].data_type) {
            case 'Energy Consumption':
              this.industryAvgEnergy = this.overviewData[i].chart_data
              this.tenantIndustryAvgEnergyData.datasets[1].data.push(this.overviewData[i].chart_data)
              break
            case 'Water Consumption':
              this.industryAvgWater = this.overviewData[i].chart_data
              this.tenantIndustryAvgWaterData.datasets[1].data.push(this.overviewData[i].chart_data)
              break
            case 'Waste Generation':
              this.industryAvgWaste = this.overviewData[i].chart_data
              this.tenantIndustryAvgWasteData.datasets[1].data.push(this.overviewData[i].chart_data)
              break
            case 'Carbon Footprint':
              this.industryAvgCarbon = this.overviewData[i].chart_data
              this.tenantIndustryAvgCarbonData.datasets[1].data.push(this.overviewData[i].chart_data)
              break
          }
        }
        const o = $h.calculateOverallLetterScore(this.energyScore, this.carbonScore, this.waterScore, this.wasteScore)
        this.overAllScore = o.score
        this.overAllScoreText = o.text
        this.overAllScoreColor = o.color
      }
      const e = $h.calculatePercentDiff(this.industryAvgEnergy, this.tenantEnergy)
      // eslint-disable-next-line
      this.energyPercent = (this.tenantEnergy === null ? 0 : e.rate)
      this.energyPercentSign = e.sign
      this.energyPercentColor = e.color
      const wt = $h.calculatePercentDiff(this.industryAvgWater, this.tenantWater)
      // eslint-disable-next-line
      this.waterPercent = (this.tenantWater === null ? 0 : wt.rate)
      this.waterPercentSign = wt.sign
      this.waterPercentColor = wt.color
      const ws = $h.calculatePercentDiff(this.industryAvgWaste, this.tenantWaste)
      // eslint-disable-next-line
      this.wastePercent = (this.tenantWaste === null ? 0: ws.rate)
      this.wastePercentSign = ws.sign
      this.wastePercentColor = ws.color
      const c = $h.calculatePercentDiff(this.industryAvgCarbon, this.tenantCarbon)
      // eslint-disable-next-line
      this.carbonPercent = (this.tenantCarbon === null ? 0 : c.rate)
      this.carbonPercentSign = c.sign
      this.carbonPercentColor = c.color
      const tenantAvgOverall = ((parseFloat(this.energyPercent) + parseFloat(this.waterPercent) + parseFloat(this.wastePercent) + parseFloat(this.carbonPercent)) / 4).toFixed(2)
      this.overAllPercent = tenantAvgOverall
      this.overAllPercentSign = tenantAvgOverall >= 0 ? 'more' : 'less'
      this.overAllPercentColor = tenantAvgOverall >= 0 ? '#2e7d32' : '#c62828'
    }
  }
}
</script>
