import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import globalComponents from './global-components'
import store from './store'
import utils from './utils'
import { darkModeKey } from '@/config.js'
import './css/main.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faStar, faStarHalf, faStarHalfStroke, faBolt, faChevronDown, faChevronUp, faDroplet, faRecycle, faLeaf, faCircleCheck, faCircleXmark, faXmark, faCheck, faLocationDot, faPenToSquare, faTrashCan, faSpinner, faLock, faCamera, faHotel, faEarthAsia, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Amplify, Auth } from 'aws-amplify'

library.add(faStar, faStarHalf, faStarHalfStroke, faBolt, faChevronDown, faChevronUp, faDroplet, faRecycle, faLeaf, faCircleCheck, faLocationDot, faCircleXmark, faXmark, faCheck, faPenToSquare, faTrashCan, faSpinner, faLock, faCamera, faHotel, faEarthAsia, faEye, faEyeSlash)

Amplify.configure({
  Auth: {
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_DORQYx4Bj',
    userPoolWebClientId: '2oa8r0aihmksbrvguhak36gkm4',
    identityPoolId: 'ap-southeast-1:8877f64a-1ec1-4684-b518-58de8bb8b2e0',
    identityPoolRegion: 'ap-southeast-1',
    oauth: {}
  },
  Storage: {
    AWSS3: {
      bucket: 'greenbalance-asset-prod',
      region: 'ap-southeast-1'
    }
  }
})

Auth.configure({
  Auth: {
    region: 'ap-southeast-1',
    userPoolId: 'ap-southeast-1_DORQYx4Bj',
    userPoolWebClientId: '2oa8r0aihmksbrvguhak36gkm4',
    identityPoolId: 'ap-southeast-1:8877f64a-1ec1-4684-b518-58de8bb8b2e0',
    identityPoolRegion: 'ap-southeast-1',
    oauth: {}
  },
  Storage: {
    AWSS3: {
      bucket: 'greenbalance-asset-prod',
      region: 'ap-southeast-1'
    }
  }
})

/* App style */
store.dispatch('setStyle', 'gb')

/* Dark mode */
if ((!localStorage[darkModeKey] && window.matchMedia('(prefers-color-scheme: dark)').matches) || localStorage[darkModeKey] === '1') {
  store.dispatch('darkMode', true)
}

/* Default title tag */
const defaultDocumentTitle = 'Green Balance'

/* Collapse mobile aside menu on route change */
router.beforeEach(to => {
  store.dispatch('asideMobileToggle', false)
  store.dispatch('asideLgToggle', false)
})

router.afterEach(to => {
  /* Set document title from route meta */
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
  if (to.name !== 'login' && to.name !== 'signup') {
    Auth.currentAuthenticatedUser().then(data => {
      store.dispatch('setUser', data.attributes)
    })
  } else {
    // store.dispatch('resetUserState')
  }
  /* Full screen mode */
  store.dispatch('fullScreenToggle', !!to.meta.fullScreen)
})

const app = createApp(App)
app.use(store).use(router)
app.component('FontAwesomeIcon', FontAwesomeIcon)
globalComponents(app)
utils(app)
app.mount('#app')
