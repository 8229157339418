import {
  mdiViewDashboard,
  mdiFileChart,
  mdiChartAreaspline,
  mdiAccountBox,
  mdiHome,
  mdiAccountMultiple,
  mdiCog,
  mdiLock,
  mdiCommentProcessing,
  mdiWrench,
  mdiLightbulbOn,
  mdiCommentQuestion,
  mdiCircleSmall
} from '@mdi/js'

export const adminMenu = [
  'Main Menu',
  [
    {
      to: '/',
      icon: mdiViewDashboard,
      label: 'Dashboard',
      menu: [
        {
          to: '/',
          label: 'Greensights',
          icon: mdiCircleSmall
        },
        {
          to: '/trend',
          label: 'Trends',
          icon: mdiCircleSmall
        }
      ]
    },
    {
      to: '/admin-metric-energy',
      icon: mdiChartAreaspline,
      label: 'Key Metrics',
      menu: [
        {
          to: '/admin-metric-energy',
          label: 'Energy',
          icon: mdiCircleSmall
        },
        {
          to: '/admin-metric-carbon',
          label: 'Carbon Footprint',
          icon: mdiCircleSmall
        },
        {
          to: '/admin-metric-water',
          label: 'Water',
          icon: mdiCircleSmall
        },
        {
          to: '/admin-metric-waste',
          label: 'Waste',
          icon: mdiCircleSmall
        }
      ]
    },
    {
      to: '/data-input',
      icon: mdiFileChart,
      label: 'Data Input'
    }
  ],
  'Admin',
  [
    {
      to: '/admin-property-data',
      icon: mdiHome,
      label: 'Property Data'
    },
    {
      to: '/dashboard',
      icon: mdiAccountBox,
      label: 'Profile'
    },
    {
      to: '/dashboard',
      icon: mdiAccountMultiple,
      label: 'Users & Access'
    },
    {
      to: '/dashboard',
      icon: mdiCog,
      label: 'Settings'
    },
    {
      to: '/dashboard',
      icon: mdiLock,
      label: 'Security'
    }
  ],
  'Others',
  [
    {
      to: '/feedback',
      icon: mdiCommentProcessing,
      label: 'Feedbacks'
    },
    {
      to: '/dashboard',
      icon: mdiWrench,
      label: 'Report an Issue'
    },
    {
      to: '/faqs-list',
      icon: mdiLightbulbOn,
      label: 'FAQS'
    },
    {
      to: '/faqs',
      icon: mdiLightbulbOn,
      label: 'FAQS user'
    },
    {
      to: '/dashboard',
      icon: mdiCommentQuestion,
      label: 'Help'
    }
  ]
]

export const userMenu = [
  'Main Menu',
  [
    {
      to: '/',
      icon: mdiViewDashboard,
      label: 'Dashboard',
      menu: [
        {
          to: '/',
          label: 'Greensights',
          icon: mdiCircleSmall
        },
        {
          to: '/trend',
          label: 'Trends',
          icon: mdiCircleSmall
        }
      ]
    },
    {
      to: '/metric-energy',
      icon: mdiChartAreaspline,
      label: 'Key Metrics',
      menu: [
        {
          to: '/metric-energy',
          label: 'Energy',
          icon: mdiCircleSmall
        },
        {
          to: '/metric-carbon',
          label: 'Carbon Footprint',
          icon: mdiCircleSmall
        },
        {
          to: '/metric-water',
          label: 'Water',
          icon: mdiCircleSmall
        },
        {
          to: '/metric-waste',
          label: 'Waste',
          icon: mdiCircleSmall
        }
      ]
    },
    {
      to: '/data-input',
      icon: mdiFileChart,
      label: 'Data Input'
    }
  ],
  'Account',
  [
    {
      to: '/account-property-data',
      icon: mdiHome,
      label: 'Property Data'
    }
  ],
  'Others',
  [
    {
      to: '/post-feedback',
      icon: mdiCommentProcessing,
      label: 'Post Feedback'
    },
    {
      to: '/faqs',
      icon: mdiLightbulbOn,
      label: 'FAQS'
    }
  ]
]
