<template>
  <nav-bar />
  <aside-menu :menu="sideMenu" />
  <router-view />
  <overlay
    v-show="isAsideLgActive"
    z-index="z-30"
    @overlay-click="overlayClick"
  />
</template>
<script>
import { getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { adminMenu, userMenu } from '@/menu.js'
import NavBar from '@/components/NavBar.vue'
import AsideMenu from '@/components/AsideMenu.vue'
import Overlay from '@/components/Overlay.vue'

export default {
  setup () {
    const router = useRouter()
    const store = useStore()
    const appCon = getCurrentInstance()

    return {
      router,
      store,
      appCon
    }
  },
  components: {
    NavBar,
    AsideMenu,
    Overlay
  },
  computed: {
    isAsideLgActive () {
      return this.store.state.isAsideLgActive
    },
    sideMenu () {
      return (this.store.state.userType === '1' ? adminMenu : userMenu)
    }
  },
  methods: {
    overlayClick () {
      this.store.dispatch('asideLgToggle', false)
    }
  }
}
</script>
