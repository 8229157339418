import axios from 'axios'

const $axios = axios.create({
  baseURL: 'https://d8ndsrpct0.execute-api.ap-southeast-1.amazonaws.com/prod',
  timeout: 180000,
  headers: {
    common: {
      Accept: 'text/plain, application/json, */*'
    }
  }
})

export default $axios
