import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { apisrv as $a } from '@/utils/api'

dayjs.extend(duration)

const helpers = {
  cutText (text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  formatDate (date, format) {
    return dayjs(date).format(format)
  },
  capitalizeFirstLetter (string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber (string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency (number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substr(0, rest)
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  timeAgo (time) {
    const date = new Date(
      (time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')
    )
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow (time) {
    const startDate = dayjs(dayjs().format('YYYY-MM-DD HH:mm:ss').toString())
    const endDate = dayjs(dayjs(time).format('YYYY-MM-DD HH:mm:ss').toString())

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds
    }
  },
  isset (obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw (obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers (from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  toRGB (colors) {
    const tempColors = Object.assign({}, colors)
    const rgbColors = Object.entries(tempColors)
    for (const [key, value] of rgbColors) {
      if (typeof value === 'string') {
        if (value.replace('#', '').length === 6) {
          const aRgbHex = value.replace('#', '').match(/.{1,2}/g)
          tempColors[key] = (opacity = 1) =>
            `rgb(${parseInt(aRgbHex[0], 16)} ${parseInt(
              aRgbHex[1],
              16
            )} ${parseInt(aRgbHex[2], 16)} / ${opacity})`
        }
      } else {
        tempColors[key] = helpers.toRGB(value)
      }
    }
    return tempColors
  },
  listMonths () {
    const monthArr = []
    for (let i = 0; i < 12; i++) {
      monthArr.push({
        lib_code: dayjs(dayjs().startOf('year')).add(i, 'M').format('M'),
        lib_desc: dayjs(dayjs().startOf('year')).add(i, 'M').format('MMM')
      })
    }
    return monthArr
  },
  listYear () {
    const yearArr = []
    for (let i = -5; i < 5; i++) {
      yearArr.push({
        lib_code: dayjs().add(i, 'y').format('YYYY'),
        lib_desc: dayjs().add(i, 'y').format('YYYY')
      })
    }
    return yearArr
  },
  async allLib (p) {
    let library = []
    library = await $a.getLibrary(p, 'All')
    return library
  },
  calculatePercentDiff (val1, val2) {
    const result = (100 * ((val1 - val2) / ((val1 + val2) / 2))).toFixed(2)
    return {
      rate: result,
      sign: val1 >= val2 ? 'better' : 'worse',
      color: val1 >= val2 ? '#2e7d32' : '#c62828'
    }
  },
  calculateLetterScore (cat, filter, score) {
    if (cat === 'energy') {
      if (filter === 'TA') {
        if (score >= 0.01 && score <= 16.299) {
          return helpers.scoreA()
        } else if (score >= 16.30 && score <= 17.59) {
          return helpers.scoreB()
        } else if (score >= 17.60 && score <= 18.59) {
          return helpers.scoreC()
        } else if (score >= 18.60 && score <= 22.00) {
          return helpers.scoreD()
        } else if (score >= 22.01) {
          return helpers.scoreE()
        }
      } else if (filter === 'OR') {
        if (score >= 0.01 && score <= 200.00) {
          return helpers.scoreA()
        } else if (score >= 200.01 && score <= 250.00) {
          return helpers.scoreB()
        } else if (score >= 250.01 && score <= 300.00) {
          return helpers.scoreC()
        } else if (score >= 300.01 && score <= 350.00) {
          return helpers.scoreD()
        } else if (score >= 350.01) {
          return helpers.scoreE()
        }
      } else {
        return helpers.scoreDefault()
      }
    } else if (cat === 'carbon') {
      if (filter === 'TA') {
        if (score >= 0.01 && score <= 20.00) {
          return helpers.scoreA()
        } else if (score >= 20.01 && score <= 22.00) {
          return helpers.scoreB()
        } else if (score >= 22.01 && score <= 33.00) {
          return helpers.scoreC()
        } else if (score >= 33.01 && score <= 45.00) {
          return helpers.scoreD()
        } else if (score >= 45.01) {
          return helpers.scoreE()
        }
      } else if (filter === 'OR') {
        if (score >= 0.01 && score <= 60.00) {
          return helpers.scoreA()
        } else if (score >= 60.01 && score <= 75.00) {
          return helpers.scoreB()
        } else if (score >= 75.01 && score <= 90.00) {
          return helpers.scoreC()
        } else if (score >= 90.01 && score <= 105.00) {
          return helpers.scoreD()
        } else if (score >= 105.01) {
          return helpers.scoreE()
        }
      } else {
        return helpers.scoreDefault()
      }
    } else if (cat === 'water') {
      if (filter === 'TA') {
        if (score >= 0.01 && score <= 160.00) {
          return helpers.scoreA()
        } else if (score >= 160.01 && score <= 190.00) {
          return helpers.scoreB()
        } else if (score >= 190.01 && score <= 200.00) {
          return helpers.scoreC()
        } else if (score >= 200.01 && score <= 230.00) {
          return helpers.scoreD()
        } else if (score >= 231.01) {
          return helpers.scoreE()
        }
      } else if (filter === 'OR') {
        if (score >= 0.01 && score <= 2200.00) {
          return helpers.scoreA()
        } else if (score >= 2200.01 && score <= 2900.00) {
          return helpers.scoreB()
        } else if (score >= 2900.01 && score <= 3600.00) {
          return helpers.scoreC()
        } else if (score >= 3600.01 && score <= 4500.00) {
          return helpers.scoreD()
        } else if (score >= 4500.01) {
          return helpers.scoreE()
        }
      } else {
        return helpers.scoreDefault()
      }
    } else if (cat === 'waste') {
      if (filter === 'TA') {
        if (score >= 0.01 && score <= 0.95) {
          return helpers.scoreA()
        } else if (score >= 0.96 && score <= 1.07) {
          return helpers.scoreB()
        } else if (score >= 1.08 && score <= 1.13) {
          return helpers.scoreC()
        } else if (score >= 1.14 && score <= 1.28) {
          return helpers.scoreD()
        } else if (score >= 1.29) {
          return helpers.scoreE()
        }
      } else if (filter === 'OR') {
        if (score >= 0.01 && score <= 11.00) {
          return helpers.scoreA()
        } else if (score >= 11.01 && score <= 15.00) {
          return helpers.scoreB()
        } else if (score >= 15.01 && score <= 19.00) {
          return helpers.scoreC()
        } else if (score >= 19.01 && score <= 23.00) {
          return helpers.scoreD()
        } else if (score >= 23.01) {
          return helpers.scoreE()
        }
      } else {
        return helpers.scoreDefault()
      }
    }
  },
  calculateOverallLetterScore (energy, carbon, water, waste) {
    let e = 0
    switch (energy) {
      case 'A':
        e = 1
        break
      case 'B':
        e = 2
        break
      case 'C':
        e = 3
        break
      case 'D':
        e = 4
        break
      case 'E':
        e = 5
        break
    }
    let c = 0
    switch (carbon) {
      case 'A':
        c = 1
        break
      case 'B':
        c = 2
        break
      case 'C':
        c = 3
        break
      case 'D':
        c = 4
        break
      case 'E':
        c = 5
        break
    }
    let wt = 0
    switch (water) {
      case 'A':
        wt = 1
        break
      case 'B':
        wt = 2
        break
      case 'C':
        wt = 3
        break
      case 'D':
        wt = 4
        break
      case 'E':
        wt = 5
        break
    }
    let ws = 0
    switch (waste) {
      case 'A':
        ws = 1
        break
      case 'B':
        ws = 2
        break
      case 'C':
        ws = 3
        break
      case 'D':
        ws = 4
        break
      case 'E':
        ws = 5
        break
    }
    const sum = e + c + wt + ws
    const avg = (sum / 4) || 0

    if (avg >= 0.0001 && avg <= 1.9999999) {
      return helpers.scoreA()
    } else if (avg >= 2 && avg <= 2.9999999) {
      return helpers.scoreB()
    } else if (avg >= 3 && avg <= 3.9999999) {
      return helpers.scoreC()
    } else if (avg >= 4 && avg <= 4.9999999) {
      return helpers.scoreD()
    } else if (avg >= 5) {
      return helpers.scoreE()
    } else {
      return helpers.scoreDefault()
    }
  },
  scoreDefault () {
    return {
      score: '--',
      text: '--',
      color: '#000'
    }
  },
  scoreA () {
    return {
      score: 'A',
      text: 'Good',
      color: '#2e7d32'
    }
  },
  scoreB () {
    return {
      score: 'B',
      text: 'Above Average',
      color: '#2e7d32'
    }
  },
  scoreC () {
    return {
      score: 'C',
      text: 'Average',
      color: '#827717'
    }
  },
  scoreD () {
    return {
      score: 'D',
      text: 'Below Average',
      color: '#c62828'
    }
  },
  scoreE () {
    return {
      score: 'E',
      text: 'Poor',
      color: '#c62828'
    }
  }
}

const install = (app) => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
