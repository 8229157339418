import Chart from './chart/Chart.vue'
import Notification from './notification/Main.vue'
import { Modal, ModalHeader, ModalBody, ModalFooter } from './modal'
import { Alert } from './alert'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from './tab'
import {
  AccordionGroup,
  AccordionItem,
  Accordion,
  AccordionPanel
} from './accordion'

export default (app) => {
  app.component('Chart', Chart)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('Notification', Notification)
  app.component('Modal', Modal)
  app.component('ModalHeader', ModalHeader)
  app.component('ModalBody', ModalBody)
  app.component('ModalFooter', ModalFooter)
  app.component('Alert', Alert)
  app.component('TabGroup', TabGroup)
  app.component('TabList', TabList)
  app.component('Tab', Tab)
  app.component('TabPanels', TabPanels)
  app.component('TabPanel', TabPanel)
  app.component('AccordionGroup', AccordionGroup)
  app.component('AccordionItem', AccordionItem)
  app.component('Accordion', Accordion)
  app.component('AccordionPanel', AccordionPanel)
}
