import { createRouter, createWebHistory } from 'vue-router'
import { Auth } from 'aws-amplify'
import DashboardInsight from '@/views/DashboardInsight.vue'

const routes = [
  {
    meta: {
      title: 'Dashboard Insights',
      requiresAuth: true
    },
    path: '/',
    name: 'dashboard-insight',
    component: DashboardInsight
  },
  {
    meta: {
      title: 'Trend',
      requiresAuth: true
    },
    path: '/trend',
    name: 'trend',
    component: () => import(/* webpackChunkName: "login" */ '@/views/DashboardTrend.vue')
  },
  {
    meta: {
      title: 'Login',
      fullScreen: true,
      requiresAuth: false
    },
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue')
  },
  {
    meta: {
      title: 'Sign Up',
      fullScreen: true,
      requiresAuth: false
    },
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "login" */ '@/views/SignUp.vue')
  },
  {
    meta: {
      title: 'Account Property Data',
      requiresAuth: true
    },
    path: '/account-property-data',
    name: 'account-property-data',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AccountPropertyData.vue')
  },
  {
    meta: {
      title: 'Admin Property Data',
      requiresAuth: true
    },
    path: '/admin-property-data',
    name: 'admin-property-data',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AdminPropertyData.vue')
  },
  {
    meta: {
      title: 'Metric Energy',
      requiresAuth: true
    },
    path: '/metric-energy',
    name: 'metric-energy',
    component: () => import(/* webpackChunkName: "login" */ '@/views/MetricEnergy.vue')
  },
  {
    meta: {
      title: 'Metric Water',
      requiresAuth: true
    },
    path: '/metric-water',
    name: 'metric-water',
    component: () => import(/* webpackChunkName: "login" */ '@/views/MetricWater.vue')
  },
  {
    meta: {
      title: 'Metric Waste',
      requiresAuth: true
    },
    path: '/metric-waste',
    name: 'metric-waste',
    component: () => import(/* webpackChunkName: "login" */ '@/views/MetricWaste.vue')
  },
  {
    meta: {
      title: 'Metric Carbon',
      requiresAuth: true
    },
    path: '/metric-carbon',
    name: 'metric-carbon',
    component: () => import(/* webpackChunkName: "login" */ '@/views/MetricCarbon.vue')
  },
  {
    meta: {
      title: 'Metric Energy',
      requiresAuth: true
    },
    path: '/admin-metric-energy',
    name: 'admin-metric-energy',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AdminMetricEnergy.vue')
  },
  {
    meta: {
      title: 'MetricWater',
      requiresAuth: true
    },
    path: '/admin-metric-water',
    name: 'admin-metric-water',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AdminMetricWater.vue')
  },
  {
    meta: {
      title: 'Metric Waste',
      requiresAuth: true
    },
    path: '/admin-metric-waste',
    name: 'admin-metric-waste',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AdminMetricWaste.vue')
  },
  {
    meta: {
      title: 'Metric Carbon',
      requiresAuth: true
    },
    path: '/admin-metric-carbon',
    name: 'admin-metric-carbon',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AdminMetricCarbon.vue')
  },
  {
    meta: {
      title: 'Admin Property Data Detail',
      requiresAuth: true
    },
    path: '/admin-property-data/:id',
    name: 'admin-property-data-detail',
    component: () => import(/* webpackChunkName: "login" */ '@/views/AdminPropertyDataDetail.vue')
  },
  {
    meta: {
      title: 'Data Input',
      requiresAuth: true
    },
    path: '/data-input',
    name: 'data-input',
    component: () => import(/* webpackChunkName: "login" */ '@/views/DataInput.vue')
  },
  {
    meta: {
      title: 'Data Input Detail',
      requiresAuth: true
    },
    path: '/data-input/:id',
    name: 'data-input-detail',
    component: () => import(/* webpackChunkName: "login" */ '@/views/DataInputDetail.vue')
  },
  {
    meta: {
      title: 'FAQs List',
      requiresAuth: true
    },
    path: '/faqs-list',
    name: 'faqs-list',
    component: () => import(/* webpackChunkName: "login" */ '@/views/FAQList.vue')
  },
  {
    meta: {
      title: 'FAQ Detail',
      requiresAuth: true
    },
    path: '/faqs-list/:id',
    name: 'faqs-list-detail',
    component: () => import(/* webpackChunkName: "login" */ '@/views/FAQDetail.vue')
  },
  {
    meta: {
      title: 'FAQs',
      requiresAuth: true
    },
    path: '/faqs',
    name: 'faqs',
    component: () => import(/* webpackChunkName: "login" */ '@/views/FAQs.vue')
  },
  {
    meta: {
      title: 'Feedback List',
      requiresAuth: true
    },
    path: '/feedback',
    name: 'feedback',
    component: () => import(/* webpackChunkName: "login" */ '@/views/FeedbackList.vue')
  },
  {
    meta: {
      title: 'Feedback Detail',
      requiresAuth: true
    },
    path: '/feedback/:id',
    name: 'feedback-detail',
    component: () => import(/* webpackChunkName: "login" */ '@/views/FeedbackDetail.vue')
  },
  {
    meta: {
      title: 'Post Feedback',
      requiresAuth: true
    },
    path: '/post-feedback',
    name: 'post-feedback',
    component: () => import(/* webpackChunkName: "login" */ '@/views/PostFeedbackList.vue')
  },
  {
    meta: {
      title: 'Post Feedback Detail',
      requiresAuth: true
    },
    path: '/post-feedback/:id',
    name: 'post-feedback-detail',
    component: () => import(/* webpackChunkName: "login" */ '@/views/PostFeedbackDetail.vue')
  },
  {
    meta: {
      title: 'Error Page',
      requiresAuth: false
    },
    path: '/error-page',
    name: 'error-page',
    component: () => import(/* webpackChunkName: "login" */ '@/views/ErrorPage.vue')
  },
  {
    meta: {
      title: 'Error Page',
      fullScreen: true,
      requiresAuth: false
    },
    path: '/:pathMatch(.*)*',
    name: 'error-page',
    component: () => import(/* webpackChunkName: "login" */ '@/views/ErrorPage.vue')
  }
]

const router = createRouter({
  history: createWebHistory(''),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

router.beforeResolve((to, from, next) => {
  Auth.currentAuthenticatedUser().then(user => {
    if (to.path === '/login') {
      router.push({ path: '/' })
    } else {
      next()
    }
  }).catch(() => {
    if (to.meta.requiresAuth) {
      router.push({ path: '/login' })
    } else {
      next()
    }
  })
})

export default router
